<template>
  <LayoutParametrage title="Dossiers Promoteurs">
    <template #current_page>Timeline</template>
    <template #title_table> Modifier Timeline </template>

    <template #list_table>
      <div class="flex flex-col">
        <div class="flex flex-col w-full">
          <ValidationObserver
            v-slot="{ handleSubmit }"
            class="w-full"
            ref="formInformations"
          >
            <form
              @submit.prevent="handleSubmit(saveTimeline)"
              class="w-full flex flex-col space-y-20"
            >
              <div
                class="flex w-full space-x-5 sm:space-x-0 sm:flex-col sm:space-y-20"
              >
                <div class="flex flex-col w-1/2 sm:w-full">
                  <div
                    class="text-promy-gray-650 font-extrabold font-main lg:text-base xl:text-lg mb-4"
                  >
                    Desciption
                  </div>
                  <div class="flex w-full">
                    <quill-editor
                      ref="myQuillEditor"
                      v-model="form.description"
                    />
                  </div>
                </div>

                <div class="flex flex-col w-1/2 sm:w-full">
                  <div
                    class="text-promy-gray-650 font-extrabold font-main lg:text-base xl:text-lg mb-4"
                  >
                    Date
                  </div>
                  <div class="flex w-full">
                    <datepicker
                      class="date-input pt-1 mb-5"
                      v-model="form.date"
                      valueType="DD-MM-YYYY"
                      format="DD-MM-YYYY"
                    />
                  </div>
                </div>
              </div>
              <div class="flex w-full">
                <div class="flex flex-col w-full">
                  <div
                    class="text-promy-gray-650 font-extrabold font-main lg:text-base xl:text-lg mb-4"
                  >
                    Logo
                  </div>
                  <div class="flex flex-col w-full">
                    <InputImage
                      :file="form.logo"
                      :selfUploader="true"
                      @upload="updateImage($event)"
                      @deleteImage="deleteImage()"
                      class="w-full"
                    />
                  </div>
                </div>
              </div>
              <div>
                <button
                  v-if="$can('edite,dossier_promoteur') && isCreateMode"
                  type="submit"
                  class="px-16 w-max rounded-full text-center sm:w-full border-2 bg-promy-green-300 border-promy-green-300 py-3 text-white uppercase font-main font-extrabold text-base hover:bg-promy-green-200 hover:border-promy-green-200 duration-100 transition-colors mt-6"
                >
                  Sauvegarder
                </button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </template>
  </LayoutParametrage>
</template>
<script>
export default {
  data() {
    return {
      action: 'Ajouter',
      syntheseUrl: 'admin/timelines',
      form: {
        description: null,
        date: null,
        logo: null,
      },
    }
  },
  created() {
    this.checkMode()
  },
  computed: {
    isCreateMode() {
      return this.$route.meta.mode !== 'edit'
    },
  },
  methods: {
    checkMode() {
      if (this.isCreateMode) return
      this.action = 'Editer'
      this.getTimeline(this.$route.params.id)
    },
    getTimeline(id) {
      this.$http.get(`admin/timelines/${id}`).then((res) => {
        this.form = res.data
      })
    },
    deleteImage() {
      this.form.logo = ''
    },
    updateImage(file) {
      this.form.logo = file
    },
    saveTimeline() {
      this.$http.post('admin/timelines', this.form).then((response) => {
        Object.assign(this.form, response.data)
        this.$toaster.success(' context général sauvegardée avec succès')
        this.$router.push('/dossiers-promoteurs/timeline')
      })
    },
    getTimelineFullUrl() {
      return this.isCreateMode
        ? `${this.syntheseUrl}`
        : `${this.syntheseUrl}/${this.$route.params.id}?_method=PUT`
    },
  },
}
</script>
